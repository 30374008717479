
.desktop {
    display: none;
    width: 100%;
    margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
    .desktop {
        display: block;
    }
}
